<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <v-card-text>
        <v-text-field
          v-model="form.key"
          label="Unique Key (e.g. enable-monopause)"
          class="mt-5"
          outlined
          :rules="[required]"
          :disabled="!!resource"
        ></v-text-field>

        <translatable-input
          v-model="form.name"
          label="Name"
        />

        <translatable-input
          v-model="form.description"
          label="Description"
          class="mt-5"
        />

        <v-text-field
          v-model="form.notes"
          label="Notes on how you intend to use it."
          outlined
          :rules="[required]"
        ></v-text-field>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableInput, TranslationLocales } from '@/components'
import { useAlphaDash, useSlugify, useTranslatable } from '@/composables'
import { required } from '@core/utils/validation'
import { } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  components: { TranslatableInput, TranslationLocales },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      name: useTranslatable(props.resource, 'name'),
      description: useTranslatable(props.resource, 'description'),
      key: props.resource ? props.resource.key : '',
      notes: props.resource ? props.resource.notes : '',
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      form.value.key = useAlphaDash(useSlugify(form.value.key))
      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      form,
      formElem,

      validate,
      required,
    }
  },
}
</script>
