import axios from '@axios'

const fetchAllAbilities = params => axios.get('/admin/abilities', { params })

const storeAbility = data => axios.post('/admin/abilities', data)

const updateAbility = (id, data) => axios.put(`/admin/abilities/${id}`, data)

const destroyAbility = id => axios.delete(`/admin/abilities/${id}`)

const destroyGain = id => axios.delete(`/admin/ability-gains/${id}`)

const destroyNeed = id => axios.delete(`/admin/ability-needs/${id}`)

const showAbilitySource = (type, id) => axios.get(`/admin/ability-sources/${type}/${id}`)

const storeAbilityGain = (type = 'quota-rule', data) => axios.post(`/admin/ability-gains/${type}`, data)

const storeAbilityNeed = (type = 'quota-rule', data) => axios.post(`/admin/ability-needs/${type}`, data)

export {
    destroyAbility, destroyGain, destroyNeed, fetchAllAbilities, showAbilitySource, storeAbility, storeAbilityGain, storeAbilityNeed, updateAbility
}

