<template>
  <div id="user-list">
    <!-- app drawer -->
    <ability-aside
      v-if="formReady"
      v-model="isAddNewAbilitySidebarActive"
      :resource="resource"
      @changed="loadAbilities(); isAddNewAbilitySidebarActive = false"
    ></ability-aside>

    <!-- user total card -->
    <v-row class="mb-5">
      <v-col
        v-for="total in totalLocal"
        :key="total.total"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        Search &amp; Filter
      </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- search filter -->
        <v-col
          cols="12"
          sm="6"
          class="d-flex"
        >
          <v-text-field
            v-model="searchQuery"
            placeholder="Search"
            outlined
            hide-details
            dense
            class="user-search me-3 mb-4"
          >
          </v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <div class="d-flex justify-end flex-wrap">
            <template
              v-if="selectedRows.length"
            >
              <v-btn
                color="success"
                class="mb-4 me-3"
                :loading="loading"
                @click.stop="updateAllAbilities({ active: 1 })"
              >
                <span>Activate</span>
              </v-btn>
              <v-btn
                color="warning"
                class="mb-4 me-3"
                :loading="loading"
                @click.stop="updateAllAbilities({ active: 0 })"
              >
                <span>Deactive</span>
              </v-btn>
            </template>
            <v-btn
              color="primary"
              class="mb-4 me-3"
              :loading="loading"
              @click.stop="addNewResoure"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
              <span>Add New Ability</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="abilityListTable"
        :options.sync="options"
        :server-items-length="totalListTable"
        :loading="loading"
        show-select
      >
        <!-- ID -->
        <template #[`item.id`]="{item}">
          <span>{{ item.id }}</span>
        </template>

        <!-- Key -->
        <template #[`item.key`]="{item}">
          <span
            class="cursor-pointer"
            @click="setAbility(item)"
          >
            {{ item.key }}
          </span>
        </template>

        <!-- Name -->
        <template #[`item.name`]="{item}">
          <span
            class="cursor-pointer"
            @click="setAbility(item)"
          >
            {{ item.name.en }}
          </span>
        </template>

        <!-- Description -->
        <template #[`item.description`]="{item}">
          {{ item.description.en }}
        </template>

        <!-- Active -->
        <template #[`item.status`]="{item}">
          <v-chip :color="item.active ? 'success' : ''">
            {{ item.active ? 'Active' : 'Inactive' }}
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                @click="setAbility(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <span>Update</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                link
                @click="deleteAbility(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { destroyAbility } from '@api/common/ability'
import {
mdiAccountOutline,
mdiDeleteOutline,
mdiDotsVertical,
mdiExportVariant,
mdiFileDocumentOutline,
mdiPencil,
mdiPlus,
mdiSquareEditOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

// sidebar
import { useNotifyErrors } from '@/composables'
import { avatarText } from '@core/utils/filter'
import AbilityAside from './AbilityAside.vue'

import useAbilityList from './useAbilityList'

export default {
  components: {
    AbilityAside,
  },
  setup() {
    const {
      abilityListTable,
      tableColumns,
      searchQuery,
      totalListTable,
      loading,
      options,
      totalLocal,
      selectedRows,

      loadAbilities,
      updateAllAbilities,
    } = useAbilityList()

    const isAddNewAbilitySidebarActive = ref(false)
    const formReady = ref(true)
    const resource = ref(false)

    // const abilityegoriesOptions = commonStore.state.abilityegories.map(ability => ({ title: ability.name.en, value: ability.id }))

    const deleteAbility = ability => destroyAbility(ability.id).then(loadAbilities).catch(useNotifyErrors)

    const setAbility = ability => {
      resource.value = ability
      isAddNewAbilitySidebarActive.value = true
      formReady.value = false
      setTimeout(() => {
        formReady.value = true
      })
    }

    const addNewResoure = () => {
      resource.value = null
      formReady.value = false
      isAddNewAbilitySidebarActive.value = true
      setTimeout(() => {
        formReady.value = true
      })
    }

    return {
      abilityListTable,
      tableColumns,
      searchQuery,
      totalListTable,

      // categoriesOptions,
      loading,
      options,
      totalLocal,
      isAddNewAbilitySidebarActive,
      formReady,
      resource,
      selectedRows,
      addNewResoure,

      avatarText,
      loadAbilities,
      deleteAbility,
      setAbility,
      updateAllAbilities,

      // icons
      icons: {
        mdiPencil,
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
