import { fetchAllAbilities, updateAbility } from '@/api/common/ability'
import { useTableOptions } from '@/composables'
import { computed, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default () => {
  const abilityListTable = ref([])

  const tableColumns = [
    { text: 'ID', value: 'id' },
    { text: 'KEY', value: 'key' },
    { text: 'Name', value: 'name', sortable: false },
    { text: 'Description', value: 'description', sortable: false },
    { text: 'Notes', value: 'notes' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
    },
  )
  const searchQuery = ref(cached[1] || '')
  const totalListTable = ref(0)
  const loading = ref(false)
  const totalLocal = ref([])
  const selectedRows = ref([])

  const abilityOptions = computed(() =>
    abilityListTable.value.map(ability => ({
      title: ability.name.en,
      value: ability.id,
    })),
  )

  // fetch data
  const loadAbilities = () =>
    fetchAllAbilities(
      useTableOptions(options.value, {
        search: searchQuery.value,

        // ...query,

        // root_only: 1,
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data

        abilityListTable.value = records
        totalListTable.value = pagination.total
        totalLocal.value = null

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  const updateAllAbilities = async (updates = {}) => {
    loading.value = true
    for (const ability of selectedRows.value) {
      const data = { ...updates }
      data.name = ability.name
      await updateAbility(ability.id, data)
    }
    loadAbilities()
    loading.value = false
    selectedRows.value = []
  }

  const cachable = [options, searchQuery]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(
    cachable,
    debounce(() => {
      const newPage = options.value.page

      const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

      if (newPage >= 2 && hasDiff) {
        options.value.page = 1
      }

      loading.value = true
      selectedRows.value = []
      loadAbilities()
      cache()
    }, 300),
  )

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    abilityListTable,
    abilityOptions,
    tableColumns,
    searchQuery,
    totalListTable,
    loading,
    options,
    totalLocal,
    selectedRows,
    loadAbilities,
    updateAllAbilities,
  }
}
